import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import LeadForm from "../components/form"
import Contactform from "../components/contact"

const GetHelpPage = () => (
  <Layout>
    <Seo title="Get Help" />

    <main>
      <div className="px-4 mx-auto mt-10 max-w-xlg text-center">
        <h1 className="mb-4 text-4xl text-gray-900 tracking-tight font-extrabold">
          Get the help you deserve
        </h1>
        <p className="text-2xl text-indigo-600 font-semibold bg-gray-50">
          We would love to help, no matter what your Medicare situation is. Fill
          out the form and we will be in touch shortly or call us at{" "}
          <span className="font-bold">844-565-1957</span>.
        </p>
      </div>
      <Contactform />

      {/* <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
<script>
  hbspt.forms.create({
	region: "na1",
	portalId: "21206617",
	formId: "b2d598b7-df2f-4ac0-b3a9-1982d85a112d"});
</script> */}
    </main>
  </Layout>
)

export default GetHelpPage
